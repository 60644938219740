exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-rent-tsx": () => import("./../../../src/pages/rent.tsx" /* webpackChunkName: "component---src-pages-rent-tsx" */),
  "component---src-templates-activity-page-tsx": () => import("./../../../src/templates/activity-page.tsx" /* webpackChunkName: "component---src-templates-activity-page-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/news-article.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-news-overview-tsx": () => import("./../../../src/templates/news-overview.tsx" /* webpackChunkName: "component---src-templates-news-overview-tsx" */),
  "component---src-templates-standard-page-tsx": () => import("./../../../src/templates/standard-page.tsx" /* webpackChunkName: "component---src-templates-standard-page-tsx" */)
}

